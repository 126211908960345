<template>
  <div class="c-alert">
    <template v-for="item in list" :key="item.memId">
      <div v-if="item.success">{{ item.memId }}의 계정이 생성 되었습니다.</div>
      <div v-else>{{ item.memId }}의 계정 생성이 실패했습니다.</div>
    </template>
  </div>
</template>

<script>
export default {
  name: 'CreateAlert',
  props: ['list']
}
</script>

<style scoped>
.c-alert {position: fixed;right: 30px;bottom: 30px;display: flex;flex-direction: column;gap: 10px;}
.c-alert > div {background-color: #fff;border-radius: 6px;padding: 15px 30px;box-shadow: 3px 3px 6px 0 rgba(0, 0, 0, 0.1);border: solid 1px #b1b1b1;}
</style>
